.wbutton.main {
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    background-color: #17886C;
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.wbutton {
    text-align: center;
    width: 100%;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.wbutton.small {
    max-width: 100px;
}


.wbutton.main:hover {
    background-color: #189b7b;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);

}


.wbutton.secondary:hover {
    background-color: #fdfdfd;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}


.wbutton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.wbutton.warning {
    background-color: #FB2B44;
    color: white;
}

.wbutton.secondary {
    border: 1px solid black;
    background-color: #ffffff00;
    color: black;
}


.logo-container {
    position: absolute;
    top: 0;
    height: 60px;
    margin-top: 20pt;
}
.message{
    padding: 10px;
    height: 1em;
    font-size: 12px;
    border-radius: 10px;
    background-color: #f0f0f0;
    color: #fff;
    font-weight: bold;
}

.message.warn {
    background-color: rgb(255, 80, 80);
}

.main-logo {
    background-image: url('../images/logo_b.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 170px;
    height: 100%;
}


.info-txt {
    font-size: 14px;
    color: #030811;
}

.link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}


.link-button {
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    color: #030811;
}

.separator-line {
    width: 100%;
    height: 0.5px;
    border: 1px;
    background-color: #ccc;
    margin: 20px 0;
}