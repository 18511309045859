.auth-container {
  height: 100vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  flex-wrap: wrap;
  /* Add your specific styling here */
}

.auth-info {
  text-align: right;
  flex: 2; /* default 0 */
  min-width: 200pt;
  padding-right: 5em;
  /* Style for the info section */
}


.auth-form {
  flex: 1; /* default 0 */
  min-width: 200pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  /* Style for the form section */
}

.auth-form input[type="email"],
.auth-form input[type="password"] {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  appearance: none;
}

.auth-form input[type="email"]::placeholder,
.auth-form input[type="password"]::placeholder {
  font-size: 1em;
  color: #888;
}

.auth-form .error-msg {
  color: red;
  margin-top: 15px;
}

.scrollable {
  overflow-y: auto;
}

.terms-and-conditions {
  cursor: pointer;
}

.google-signin {
  background-image: url('../images/cwg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: #008be0;
  cursor: pointer;
  box-shadow: #008bed00 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 85%;
  padding: 15px;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  height: 25px;
}

.google-signin:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 1px 15px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px
}

.google-signin:focus {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .auth-info {
    text-align: center;
    padding-top: 30pt;
    padding-right: 0;
  }
  
  /*
  .auth-form {
    width: 100%;
    padding: 16px;
  } */
}
