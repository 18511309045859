/* Styling for the navigation tabs */
.nav-tabs {
  display: flex;
  justify-content: space-around;
  border-bottom: 3px solid #dee2e6;
}

.nav-item {
  list-style: none;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  color: #495057;
  background: none;
}

.nav-item a {
  text-decoration: none;
}

.nav-item:hover {
  color: #495057;
}

/* Active tab styling */
.nav-item.active {
  border-bottom: 1px solid #17886C;
  color: #141619;
}
/* Styling for individual Tabs */
.tab-content {
  margin: 0 15px;
  padding: 10px 20px; 
  font-size: 1em;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s ease;
}

/* Hover effect for Tabs */
.tab:hover {
  border-bottom: 2px solid #3498db;
}

/* Active state styling for Tabs */
.tab.active {
  border-bottom: 2px solid #3498db;
  font-weight: bold;
}


/* Tooltip styling */
.info-tooltip {
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.info-icon:hover .info-tooltip {
  visibility: visible;
  opacity: 1;
}


.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  font-family: Arial, sans-serif;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}

.hidden {
   visibility: hidden;
}
  

/* CSS in your imported CSS file */
.navbar {
  display: flex ;
  top: 0;
  height: 40pt;
  background-color: #ffffff;
  color: #030811;
  padding: 10px 20px;
  z-index: 1000; /* Ensures the navbar stays above other content */
  justify-content: space-between;
  padding-left: 20pt;
  padding-right:  20pt;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.dashboard {
  padding-top: 50px; /* Adjusted space for the fixed navbar */
}


.dashboard h1 {
  font-size: 2.5em;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.dashboard label {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.dashboard .inputs {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.dashboard input[type="text"],
.dashboard select,
.dashboard input[type="range"] {
  width: calc(100% - 20px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  appearance: none;
}

.dashboard input[type="text"]::placeholder,
.dashboard input[type="range"]::placeholder {
  font-size: 1em;
  color: #888;
}

.dashboard input[type="range"] {
  appearance: none;
}

.dashboard input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--thumb-color);
  cursor: pointer;
  border: 0; /* Remove border or style as you prefer */
}

.dashboard input[type="range"]::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: var(--thumb-color);
  cursor: pointer;
  border: 0; /* Remove border or style as you prefer */
}


.run-tests-btn {
  background-color: #17886C;
  color: white;
}
/* true as in running test */
.run-tests-btn.true { 
  background-color: #FB2B44;
  color: white;
}

.run-tests-btn.true:hover { 
  background-color: #fc5064;
  color: white;
}

.run-tests-btn:hover {
  background-color: #189b7b;
}

.run-tests-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.dashboard pre {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
  overflow-x: auto;
}


.test-summary {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.test-summary h2 {
  margin-bottom: 20px;
  font-size: 1.5em;
}

.test-summary p {
  margin: 10px 0;
  font-size: 1em;
}


/* CSS in your imported CSS file */
.chart-container {
  width: 100%; /* Take the full viewport width */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.chart-container canvas {
  width: 100% !important;
  height: auto !important;
  min-height: 250pt !important;
}
.info-icon {
  position: relative;
  cursor: pointer;
}

.info-tooltip {
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  top: 20px;
  left: 20px;
  z-index: 10000;
  font-size: 14px;
  font-weight: normal;
  width: 250px;
  height: 50px;
  color: #030811;
  text-align: left;
  border-radius: 8px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
}

.info-tooltip-large{
  position: absolute;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  top: 20px;
  left: 20px;
  z-index: 10000;
  font-size: 14px;
  font-weight: normal;
  min-width: 250px;
  height: auto;
  color: #030811;
  text-align: left;
  border-radius: 8px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  z-index: 2;
  overflow-y: auto;
  max-height: 80%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.popup-content {
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
}
/* Media query for larger screens */
@media (min-width: 768px) {
  .chart-container canvas {
    width: 45% !important;
  height: auto !important;
    
  }
}

/* Media query for smaller screens */
@media (max-width: 767px) {
  .chart-container canvas {
    width: 100% !important;
    margin-bottom: 20px;
  height: auto !important;

  }
}
