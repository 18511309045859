.discover-container {
    width: 100%;
    overflow-x: auto;
    margin-top: 10pt !important;
}

.discover-container>div {
    margin: 0 auto;
}

.discover-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
    table-layout: fixed;
    /* Use a fixed layout algorithm. Remove to narrow table down. */
}

.url-cell {
    width: 100pt !important;
    /* Force width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    direction: rtl;
    /* Set direction to right-to-left */
}

.url-cell span {
    direction: ltr;
    /* Reset direction to left-to-right in child element */
    display: inline-block;
}

.discover-table th,
.discover-table td {
    padding: 12px 15px;
}

.discover-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.status-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
}

.accessible {
    background-color: red;
}

.protected {
    background-color: green;
}

.existing {
    background-color: yellow;
}

.erroneous {
    background-color: grey;
}

.auth-cookie {
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 12px;
    background-color: #f0f0f0;
    color: #000;
    font-weight: bold;
}

.method-cookie {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 10px;
    background-color: #f0f0f0;
    color: #fff;
    font-weight: bold;
}

.method-cookie.DELETE {
    /* Increase red and reduce green and blue for a more vibrant pink/red */
    background-color: rgb(255, 80, 80);
}

.method-cookie.GET {
    /* Increase green for a more vibrant green, keep red and blue moderate */
    background-color: rgb(50, 205, 50);
}

.method-cookie.POST {
    /* Increase blue and red for a more vibrant purple */
    background-color: rgb(128, 0, 255);
}

.method-cookie.PUT {
    /* Increase all colors slightly, but keep it desaturated for grey/put */
    background-color: rgb(192, 192, 192);
}

.auth-cookie.accessible {
    color: red;
}

.auth-cookie.protected {
    color: green;
}

.auth-cookie.existing {
    color: yellow;
}

.auth-cookie.erroneous {
    color: grey;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px; /* Adjust spacing between items */
    margin-bottom: 20px;
}

.filter {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    outline: none;
  }
  
  .filter-search {
    flex-basis: 48%; /* Takes 2/4 of the space */
  }
  
  .filter:not(.filter-search) {
    flex-basis: 16%; /* Divides remaining 2/4 space equally */
  }
  
  .filter:focus {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .filter::placeholder {
    color: #bbb;
  }